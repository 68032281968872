import React from 'react'
import { CTA, Hero, Intro, NuggetCTA, Schedule, UpcomingStartDates } from 'cufc-ui'
import ContactComponent from '../contact/ContactComponent'
import GoogleMapComponent from '../../components/GoogleMapComponent'

const HomeView = ({ onNavigationClick }) => {
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="bg-white">
      <Hero onNavigationClick={onNavigationClick} />
      <NuggetCTA onNavigationClick={onNavigationClick} />
      <Intro onNavigationClick={scrollToElement} />
      <Schedule />
      <UpcomingStartDates onNavigationClick={onNavigationClick} />
      <ContactComponent />
      <GoogleMapComponent />
      <CTA onNavigationClick={onNavigationClick} />
    </div>
  )
}

export default HomeView
