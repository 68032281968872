import React from 'react'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'

const mapContainerStyle = {
  width: '100%',
  height: '400px',
}
const location = {
  lat: 39.95353718514398,
  lng: -82.82434376570333,
}

const googleMapsApiKey = 'AIzaSyC2DYybHmsvRkIEKhVsDk8QLLFdC85lKMA'

// https://visgl.github.io/react-google-maps/docs

const GoogleMapComponent: React.FC = () => {
  return (
    <APIProvider apiKey={googleMapsApiKey}>
      <Map
        style={mapContainerStyle}
        defaultCenter={location}
        defaultZoom={15}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      >
        <Marker position={location} />
      </Map>
    </APIProvider>
  )
}

export default GoogleMapComponent
