import React, { useState, useEffect } from 'react'
import { LynxCup2025SignUp } from 'cufc-ui'

const apiURL = process.env.REACT_APP_API_SERVER_URL

const LynxCup2025Component = () => {
  const [slotsFilled, setSlotsFilled] = useState({
    longsword1: 0,
    longsword2: 0,
    longsword3: 0,
    rapier: 0,
    swordBuckler: 0,
    saber: 0,
    mg: 0,
  })

  useEffect(() => {
    const fetchSlotsFilled = async () => {
      try {
        const response = await fetch(`${apiURL}/api/events/getSlotsFilled`)
        if (!response.ok) {
          throw new Error(
            `Failed to fetch slots filled: ${response.statusText}`,
          )
        }
        const data = await response.json()
        setSlotsFilled(data)
      } catch (error) {
        console.error('Error fetching slots filled:', error)
      }
    }

    fetchSlotsFilled()
  }, [])

  const submitFormData = async (formData) => {
    try {
      await sendEmail(formData)
      await createPaymentLink(formData.events)
    } catch (error) {
      console.error('Error processing form data:', error)
    }
  }

  const sendEmail = async (formData) => {
    const response = await fetch(
      `${apiURL}/api/events/sendEventEmail`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      },
    )

    if (!response.ok) {
      throw new Error(`Failed to send email: ${response.statusText}`)
    }
    console.log('Email sent successfully!')
  }

  const createPaymentLink = async (events) => {
    const name = generatePaymentName(events)
    const amount = calculateTotalPrice(events)

    const response = await fetch(`${apiURL}/api/square/createPaymentLink`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, amount }),
    })

    if (!response.ok) {
      throw new Error(`Failed to create payment link: ${response.statusText}`)
    }

    const paymentData = await response.json()
    window.location.href = paymentData.url // Redirect to payment link
  }

  const generatePaymentName = (events) => {
    const eventNames = events.join(', ')
    return `Lynx Cup 2025 Registration - ${eventNames}`
  }

  const calculateTotalPrice = (events) => {
    const basePrice = 4500; // Base price in cents
    const additionalEventPrice = 4500; // Additional price per event in cents
    const excludedEvent = 'Marginalized Genders Longsword';
  
    // Calculate total price
    const total = events.reduce((total, event) => {
      if (event !== excludedEvent) {
        return total + additionalEventPrice;
      }
      return total;
    }, basePrice);
  
    return total;
  };

  return (
    <div className="bg-white">
      <LynxCup2025SignUp
        onSubmit={submitFormData}
        slotsFilled={slotsFilled}
      />
    </div>
  )
}

export default LynxCup2025Component
