import React from 'react'
import { Classes, SmallHero } from 'cufc-ui'

const ClassesView = ({ onNavigationClick }) => {
  return (
    <div className="bg-white">
      <>
        <SmallHero pageTitle="Classes" />
        <Classes onNavigationClick={onNavigationClick} />
      </>
    </div>
  )
}

export default ClassesView
